<template>
  <div>
    <b-overlay :show="loading">
      <div class="row" style="min-height: 250px">
        <div class="col-6 col-xs-6 col-md product-item" v-for="product in products" :key="product._id">
          <single-product :data="product"></single-product>
        </div>
        <slot></slot>
      </div>
    </b-overlay>
  </div>
</template>

<script>
//import singleProduct from "../components/singleProduct";
const singleProduct = () => import(/* webpackChunkName: "singleproduct" */"../components/singleProduct.vue");

export default {
  components: {
    singleProduct,
  },
  props: ["id", "params"],
  data() {
    return {
      products: [],
      loading: true,
    };
  },
  mounted() {
    console.log("Mounted", this.params);
    this.load();
  },
  methods: {
    load() {
      let { _id } = this.$store.state.info;
      let cid = this.id;

      let query = {
        order: "createdAt",
        limit: 4,
      };
      if (cid) query.category = cid;
      if (this.params) {
        let params = this.params.split(",");
        params.forEach((p) => {
          let [key, value] = p.split("=");
          query[key] = value;
        });
      }

      const u = new URLSearchParams(query).toString();

      this.$http
        .get(`${_id}/product?${u}`)
        .then((res) => {
          this.products = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>